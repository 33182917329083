<template>
	<div class="CouponInfoSSS">
		
		<div class="Top">
			<div class="Left">
				优惠券详情 
				<i style="font-style: normal;color: rgba(0,0,0,0.2);" v-if="Coupon.CreatedAt != undefined">{{'编号('+Coupon.Id+')'}}</i>
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回优惠券列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				
				
				<li>
					<span class="Sel">券名</span>
					<em  class="Sel">
						<el-input v-model="Coupon.Name" style="width: 120px;"></el-input>
					</em>

					<span class="Sel">优惠券说明</span>
					<em  class="Sel">
						<el-input v-model="Coupon.Detail" style="width: 305px;"></el-input>
					</em>
				</li>
				
				
				
				
				<li>
					<span class="Sel">订单最小金额</span>
					<em  class="Sel">
						<el-input-number :precision="2" style="width: 120px;"   v-model="Coupon.MoneyStart" :min="0.01" :max="999999.99" :controls="false"></el-input-number>
					</em>

					<span class="Sel">可抵扣金额</span>
					<em  class="Sel">
						<el-input-number :precision="2" style="width: 120px;"   v-model="Coupon.Money" :min="0.01" :max="999999.99" :controls="false"></el-input-number>
					</em>
				</li>
			
				
				<li>
					<span class="Sel">优惠券总数</span>
					<em  class="Sel">
						<el-input-number :precision="0" style="width: 120px;"  v-model="Coupon.Quota" :min="0" :max="99999999" :controls="false"></el-input-number>
					</em>

					<span class="Sel">每人可领取数</span>
					<em  class="Sel">
						<el-input-number :precision="0" style="width: 120px;"  v-model="Coupon.SingleMax" :min="0" :max="100" :controls="false"></el-input-number>
					</em>
				</li>
				
				<li>
					<span class="Sel">可领取时段</span>
					<em  v-if="Coupon != null" class="Sel">
						<el-date-picker
						      v-model="Coupon.StartToEnd"
							  value-format="timestamp"
						      type="datetimerange"
						      range-separator="至"
						      start-placeholder="开始"
						      end-placeholder="结束">
						    </el-date-picker>
					</em>
				</li>
				
				<li>
					<span class="Sel">是否开放显示</span>
					<em  class="Sel">
						<el-switch
						  v-model="Coupon.IsPublic"
						  active-color="#13ce66">
						</el-switch>
					</em>
				</li>

				<li>
					<span class="Sel">时效类型</span>
					<em  v-if="Coupon != null" class="Sel">
						<el-select placeholder="设置状态" v-model="Coupon.ValidType">
							<el-option label="绝对时效" :value="1"></el-option>
						    <el-option label="相对时效" :value="2"></el-option>
					    </el-select>
					</em>
					
					<template v-if="Coupon.ValidType == 1">
					<span class="Sel">可使用时段</span>
					<em  v-if="Coupon != null" class="Sel">
						<el-date-picker
						      v-model="Coupon.ValidStartToEnd"
						      type="datetimerange"
						      range-separator="至"
						      start-placeholder="开始"
						      end-placeholder="结束">
						    </el-date-picker>
					</em>
					</template>
					
					<template v-if="Coupon.ValidType == 2">
						<span class="Sel">领取后有效天数</span>
						<em  class="Sel">
							<el-input-number :precision="0" style="width: 120px;"  v-model="Coupon.ValidDays" :min="0" :max="365" :controls="false"></el-input-number>
						</em>
					</template>
					
				</li>
				
				
				<li v-if="Coupon != null && Coupon.Id != ''">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{Coupon.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Coupon != null && Coupon.Id != ''">
					<span class="Sel">最后修改时间</span>
					<em class="Sel">
						{{Coupon.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span class="Sel"></span>
					<em class="Sel">
						<el-button v-if="Coupon != null && Coupon.Id != ''" @click="NewCoupon()" type="danger">提交修改</el-button>
						<el-button v-if="Coupon != null && Coupon.Id == ''" @click="NewCoupon()" type="danger">创建优惠券</el-button>
					</em>
				</li>
			</div>
		</div>
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload,InputNumber,DatePicker,Switch} from 'element-ui'
	export default {
	  name: 'MyShopCouponInfo',
	  props: {
	  },
	  data() {
	      return {
			  Coupon:{
				  Id:'',
				  Name:'',
				  Detail:'',
				  Icon:'',
				  MoneyStart:0.01,
				  Money:0.01,
				  Quota:10000,
				  StartAt:'',
				  EndAt:'',
				  StartToEnd:'',
				  ValidType:2,
				  ValidStartAt:'',
				  ValidEndAt:'',
				  ValidStartToEnd:'',
				  ValidDays:7,
				  IsPublic:true,
				  SingleMax:1,
			  },
			  FatherList:[],
			  AttrList:[],//商品属性
			  BrandList:[],
			  CategoryListOne:[],
			  CategoryListTwo:[],
			  CategoryListThree:[],
			  Content:'',
			  uploadModel:'single',//multiple 多个
			  dialogImageUrl: '',
			  dialogVisible: false,
			  PicWall:[],//图墙
			  SkuList:[],
			  CouponClassificationList:[],//店内商品分组
			  finishAttrAndSku:false,//加载完数据后再初始化子组件
			  RemoteBrandLoading:false,//远程品牌LOADING
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
		'el-input-number':InputNumber,
		'el-date-picker':DatePicker,
		'el-switch':Switch,
	  },
	  created() {
		this.GetCoupon(this.$route.params.Id)
	  },
	  methods:{
		    GetCoupon(_id){
				if(_id == '' || _id == 'add'){
					return
				}
		  		let data = {Service:'Goods',Class: 'Coupon',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Coupon = res.Data
					if(this.Coupon.IsPublic === 1){
						this.Coupon.IsPublic = true
					}else{
						this.Coupon.IsPublic = false
					}
		  		})
		    }, 
			NewCoupon(){
					
					console.log('数据',this.Coupon)
					
					if(this.Coupon.StartToEnd.length != 2){
						this.$message.error('请设置优惠券可领取的时间范围')
						return
					}
					this.Coupon.StartAt = parseInt(this.Coupon.StartToEnd[0]/1000)
					this.Coupon.EndAt = parseInt(this.Coupon.StartToEnd[1]/1000)
					
					if(this.Coupon.ValidType == 1){
						if(this.Coupon.ValidStartToEnd.length != 2){
							this.$message.error('请设置优惠券可使用的时间范围')
							return
						}
						this.Coupon.ValidStartAt = parseInt(this.Coupon.ValidStartToEnd[0]/1000)
						this.Coupon.ValidEndAt = parseInt(this.Coupon.ValidStartToEnd[1]/1000)
					}
					
					let data = {
						Service:'Goods',
						Class: 'Coupon',
						Action: 'Add',
						Name:this.Coupon.Name,
						Detail:this.Coupon.Detail,
						MoneyStart:this.Coupon.MoneyStart,
						Money:this.Coupon.Money,
						Quota:this.Coupon.Quota,
						SingleMax:this.Coupon.SingleMax,
						StartAt:this.Coupon.StartAt,
						EndAt:this.Coupon.EndAt,
						ValidType:this.Coupon.ValidType,
						ValidStartAt:this.Coupon.ValidStartAt,
						ValidEndAt:this.Coupon.ValidEndAt,
						ValidDays:this.Coupon.ValidDays,
						ShopId:'all',
					}
					if(this.Coupon.IsPublic){
						this.Coupon.IsPublic = '1'
					}else{
						this.Coupon.IsPublic = '2'
					}
					data.IsPublic = this.Coupon.IsPublic
					
					if(this.Coupon.CreatedAt != undefined){
						data.Id = this.Coupon.Id
						data.Action = 'Update'
					}
					
					this.$post(this.$store.getters.getApiHost,data)
					.then((res) => {
						
						if(res.ErrorId != 0){
							this.$message.error(res.Msg)
							return
						}
						if(this.Coupon.CreatedAt != undefined){
							this.$message.success('优惠券编辑成功')
						}else{
							this.$message.success('优惠券创建成功')
						}
						
						this.$Jump('/do/coupon/coupon_list')
						
					})
					.catch(function (response) {
						this.$message('网络请求错误')
					})
			}
	  }
	}
</script>

<style>
.CouponInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.CouponInfoSSS .Top{
	display: flex;
	align-items: center;
}
.CouponInfoSSS .Top .Left{
	
}
.CouponInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.CouponInfoSSS .List{
	margin-top: 20px;	
}
.CouponInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.CouponInfoSSS .List .One li {
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.CouponInfoSSS .List .One li  span.Sel{
	width: 120px;
	color: rgba(0,0,0,0.3);
	text-align: right;
	padding-right: 10px;
}
.CouponInfoSSS .List .One li  em.Sel{
	width: 200px;
}
.CouponInfoSSS .List .One li  i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  
.ql-editor{
        height:360px;
    }
	
	.PreviewImg{
		display: inline-block;
		width: 40px;
		height: 40px;
		border:1px dotted rgba(0,0,0,0.1);
		position: relative;
		margin-right: 5px;
	}
	.PreviewImg:hover{
		border:1px dotted rgba(228,0,0,0.7);
	}
	.PreviewImg i.Remove{
		display: none;
	}
	.PreviewImg:hover i.Remove{
		display: inline-block;
		position: absolute;
		bottom: 0px;
		right: 0px;
		background-color: rgba(228,0,0,0.8);
		padding: 2px 5px;
		border-radius: 2px;
		color: #FFFFFF;
		cursor: pointer;
		font-style: normal;
	}
	.PreviewImg .ShowContent{
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	}
	.PreviewImg .ShowContent img{
		width: 90%;
		height: 90%;
	}
</style>
